
import { defineComponent } from "vue";
import { alertController, modalController } from "@ionic/vue";
import AddKeyResult from "@/views/OKR/modals/AddKeyResult.vue";
import Utils from "@/utils/Utils";
import { KeyResultService } from "@/services/okr/KeyResultService";
import KeyResult from "@/models/okr/KeyResult";
import BaseRsp from "@/models/base/BaseRsp";
export default defineComponent({
  name: "ResultList",
  components: {},
  watch: {},
  props: {
    target: Object,
    isOPrincipal: Boolean,
  },
  data() {
    const service = new KeyResultService();
    return {
      krList: new Array<KeyResult>(0),
      service: service,
      oid: "",
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      if (this.target && this.target.id) {
        this.oid = this.target.id;
        this.service.getListByOid(this.oid, true).then(
          (r: any) => {
            this.krList = r;
          },
          (e: any) => {
            Utils.presentToastWarning(e.message);
          }
        );
      }
    },
    //删除
    async delResult(item: any) {
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要删除此关键结果吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
          },
          {
            text: "确定",
            handler: () => {
              this.deleteKeyResult(item);
            },
          },
        ],
      });
      await alert.present();
    },
    deleteKeyResult(item: any) {
      this.service
        .deleteKeuResultById(item.id)
        .then(
          (res: any) => {
            if (res) {
              Utils.presentToastSuccess("删除成功");
              let index = this.krList.indexOf(item);
              if (index >= 0) {
                this.krList.splice(index, 1);
              }
            } else {
              Utils.presentToastWarning("删除失败");
            }
          },
          (err: BaseRsp) => {
            Utils.presentToastTopWarning(err.message);
          }
        )
        .catch((err: BaseRsp) => {
          Utils.presentToastTopWarning(err.message);
        });
    },

    //编辑
    // 添加关键结果
    async edieKeyResult(item?: KeyResult) {
      const modal = await modalController.create({
        component: AddKeyResult,
        cssClass: "notice-modal-class",
        componentProps: item
          ? {
              id: item.id,
              isEdit: true,
              target: this.target,
              isOPrincipal: this.isOPrincipal,
            }
          : {
              id: this.oid,
              isEdit: false,
              target: this.target,
              isOPrincipal: this.isOPrincipal,
            },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.isRefresh) {
        this.onInit();
      }
    },
  },
});
