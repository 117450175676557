
import { IonSegment, IonSegmentButton, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import Utils from "@/utils/Utils";
import Header from "@/components/Header.vue";
import AddOKR from "../../add/AddOKR.vue";
import ResultList from "@/views/OKR/menu/editTarget/ResultList.vue";
import TaskList from "@/views/OKR/menu/editTarget/TaskList.vue";
import { nextTick } from "process";
export default defineComponent({
  name: "EditTarget",
  components: {
    IonSegment,
    Header,
    AddOKR,
    ResultList,
    TaskList,
  },
  data() {
    const params = this.$route.params;
    let info = null;
    if(params.info != undefined && params.info != null){
      info = JSON.parse(params.info.toString());
    }
    return {
      indicators: ["基本信息", "关键结果", "关联任务"], //, "视图"
      selSeg: 0,
      params: params,
      info: info,
      isTargetPrincipal: params.isTargetPrincipal == "1",
      isResultprincipal: params.isResultprincipal == "1",
      isOPrincipal: params.isOPrincipal == "1",
      addOKRIndex: 0, 
      krIndex: 1,
      taskListIndex: 2,
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      if(!this.isTargetPrincipal && this.isResultprincipal){
        this.indicators = ["关键结果"];
        this.addOKRIndex = -1;
        this.krIndex = 0;
        this.taskListIndex = -1;
      }
    },
    //滚动到顶部
    scrollToTop() {
      nextTick(() => {
        let ionContent: any = document.querySelector("#taskMain");
        if (ionContent) {
          ionContent.scrollToTop();
        }

      })
    },
    segmentChanged(ev: any) {
      this.selSeg = ev.detail.value;
      // this.scrollToTop();
    },
  },
});
